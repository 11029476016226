html {
    font-size: 62.5%;
    box-sizing: border-box;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 1rem;
  }
  
  $spacer: 2rem;
  
  .nutrition {
    font-family: sans-serif;
    font-size: 1.4rem;
    line-height: 1.429;
    max-width: 400px;
    padding: $spacer * 0.25;
    border: 1px solid;
  }
  
  .nutrition-title {
    font-size: 20px;
    font-family: inherit;
    font-weight: bold;
    text-transform: none;
  }
  .brought {
    font-size: 13px;
  }
  .black-logo{
    width:3vh;
    height:3vh;
    color:black;
  }
  .titlebrought{
    line-height: 1.1;
    margin-bottom: .5vh;
  }
  .head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .nutrition-facts {
    border-top: 10px solid;
    border-bottom: 1px solid;
  
    &,
    ul {
      list-style: none;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 0;
    }
  
    ul {
      flex: 0 0 100%;
  
      li {
        margin-left: $spacer * 0.5;
        margin-right: $spacer * -0.125;
      }
    }
  
    li {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-left: $spacer * 0.125;
      padding-right: $spacer * 0.125;
      border-top: 1px solid;
    }
  
    .nutrition-facts-section {
      border-top-width: 5px;
    }
  }
  
  .nutrition-facts-label {
    font-weight: bold;
  }
  
  .nutrition-note {
    font-size: 90%;
  }
  
  .percentageRed {
    color: red;
  }

  .percentageGreen {
    color: green;
  }

@font-face {
  font-family: 'Wallop';
  src: local('Wallop'), url(./fonts/Wallop-Regular.otf) format('opentype');
}



.background {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center center;
  position: absolute;
  background-color: #1a1e28;
}
.header0 {
  background-color: #1a1e28;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 3vw;
  column-gap: 2vh;
  font-weight: 900;
  height: 6vh;
  color: #9afee0;
}

.top-logo {
  width: 10vh;
  margin-left: 2vh;
}
.watermarkcss {
  color: #9afee0;
  font-weight: bolder;
  font-size: 250%;
  display: inline-block;
  text-decoration: none;
  
}
.nutrition {
  background-color: #fefffa;
  height: 90%;
  width: 90%;
  color: #1a1e28;
}
.igtiktokwrap {
  align-self: center;
  margin-left: 4.5vh;
}
.header1 {
  background-color: #1a1e28;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10%;
  font-size: 8vw;
  font-family: 'Wallop';
  font-style: normal;
  font-weight: 600;
  color: #fefffa;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #1a1e28;
  flex: 1;
  margin: 0;
  padding: 0;
}
.account-background {
  background-color: #1a1e28;
  width: 100%;
}
.main-container {
  flex: 1;
  background-color: #1a1e28;
}

.authwrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.frontstatement {
  font-size: 2vh;
  color: #babbbb;
  text-align: center;
  font-size: 23px;
  align-items: center;
  font-family: 'Wallop';
  font-style: normal;
  font-weight: 500;
  align-self: center;
  align-content: center;
  line-height: 130%;

}

.btnn {
  border-radius: 2em;
  border: 0.3em solid #9afee0;
  color: #9afee0;
  background-color: #9afee0;
  border-color: #9afee0;
  cursor: pointer;
  font-size: 5vmin;
  padding: 0.7em 1.5em;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: #1a1e28;

}


.getemojiswrap {
  margin-top: 2vh;
}

.prewrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.bottom-wrap {
  align-items: center;
  justify-content: center;
  margin-bottom: 2vh;
  margin-top: -2vh;
  background-color: #1a1e28;
}
.resultswrap {
  align-items: center;
  background-color: #1a1e28;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  flex: 1;
}

.icons {
  background: #1a1e28;
  font-size: 180%;
  margin-left: 1vh;
}

.icons0 {
  background: #1a1e28;
  font-size: 180%;
  margin-right: 1vh;
}

.fa-instagram {
  background-color: #1a1e28;
}

.fa-tiktok {
  background: #1a1e28;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.labels {
  font-size: 2vh;
  color: #fefffa;
  background-color: #30343d;
  border-radius: 2em;
  height: 4vh;
}
.labelsBold {
  font-weight: 900;
  font-size: 2vh;
  color: #fefffa;
  background-color: #30343d;
  border-radius: 2em;
  height: 4vh;
}

.radio_container {
  display: flex;
  justify-content: space-between;
  column-gap: 2vh;
  align-items: center;
  width: 42vh;
  height: 4vh;
  box-shadow: inset 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.15);
}

input[type='radio'] {
  appearance: none;
  display: none;
}

label {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  width: 15vh;
  height: 3vh;
  text-align: center;
  overflow: hidden;
  transition: linear 0.3s;
  color: #6e6e6edd;
}

.radiowrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.nodec-buttons-text0 {
  color: #1a1e28;
}
.nodec-buttons-text1 {
  color: #fefffa;
}
.nomarkers0::visited {
  text-decoration: none;
  color: #1a1e28;
}
.nomarkers1::visited {
  text-decoration: none;
  color: #fefffa;
}
.twinwrap {
  display: flex;
  flex-direction: row;
  column-gap: 1vh;
  margin-right: 1vh;
}
.nomarkers0 {
  text-decoration: none;
  color: #1a1e28;
}
.nomarkers1 {
  text-decoration: none;
  color: #fefffa;
}
.twinbtns0 {
  border-radius: 2em;
  background-color: #9afee0;
  padding-bottom: 0vh;
  padding-left: 1vh;
  padding-right: 1vh;
  width: 16vh;
  min-width: 15vh;
  height: 4vh;
  font-size: 1.5vh;
  font-weight: 600;
  border: #9afee0;
}
.twinbtns1 {
  border-radius: 2em;
  background-color: #30343d;
  padding-bottom: 0vh;
  padding-left: 1vh;
  padding-right: 1vh;
  width: 16vh;
  min-width: 15vh;
  height: 4vh;
  font-size: 1.5vh;
  font-weight: 600;
  border: #30343d;
}

@media only screen and (min-width: 768px) {
  .icons0 {
    background: #1a1e28;
    font-size: 250%;
    margin-left: -2vh;
  }
  .icons {
    background: #1a1e28;
    font-size: 250%;
    margin-left: 1vh;
  }
  .watermarkcss {
    color: #9afee0;
    font-weight: bolder;
    font-size: 300%;
    display: inline-block;
    text-decoration: none;
  }
  .top-logo {
    width: 10vh;
    margin-left: 2vh;
    margin-bottom: 1.5vh;
  }
  .frontstatement{
    font-size: 28px;
    margin-top: 2vh;
    line-height: 130%;

  }
}
